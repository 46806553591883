<template>
    <el-menu
        :default-active="activePath"
        class="el-menu-vertical-demo"
        @select="handleSelect"
        :router="true"
        :collapse="true"
    >
        <el-menu-item index="Dashboard" v-if="isAdmin">
            <i class="material-icons text-secondary" style="font-size: 27px"
                >dashboard</i
            >
            <template #title>Dashboard</template>
        </el-menu-item>
        <el-menu-item index="Orders">
            <i class="material-icons text-secondary" style="font-size: 27px"
                >shopping_cart</i
            >
            <template #title>Órdenes</template>
        </el-menu-item>
        <el-menu-item index="Users" v-if="isAdmin">
            <i class="material-icons text-secondary" style="font-size: 27px"
                >perm_identity</i
            >
            <template #title>Usuarios</template>
        </el-menu-item>

        <el-menu-item index="Routes" v-if="isAdmin">
            <i class="material-icons text-secondary" style="font-size: 27px"
                >earbuds</i
            >
            <template #title>Rutas</template>
        </el-menu-item>
        <el-menu-item index="Vehicles" v-if="isAdmin">
            <i class="material-icons text-secondary" style="font-size: 27px"
                >local_shipping</i
            >
            <template #title>Vehículos</template>
        </el-menu-item>
        <el-menu-item index="FleetAssigment" v-if="isAdmin">
            <i class="material-icons text-secondary" style="font-size: 27px"
                >assignment</i
            >
            <template #title>Asignación de vehículos</template>
        </el-menu-item>
    </el-menu>
</template>

<script>
import { defineComponent } from "vue";
import { ElMenu, ElMenuItem } from "element-plus";
export default defineComponent({
    name: "leftSideBar",
    components: {
        ElMenu,
        ElMenuItem,
    },
    data: function () {
        return {
            activePath: "",
        };
    },
    computed: {
        isAdmin() {
            return this.$store.getters.getUserRole == "Administrador"
                ? true
                : false;
        },
    },
    methods: {
        handleSelect() {
            this.activePath = this.$route.name;
        },
    },
    mounted() {
        this.activePath = this.$route.name;
    },
});
</script>
<style lang="scss">
.el-menu-item.is-active {
    border-bottom: 2px solid #3f69a6;
}
.el-menu-item {
    div {
        padding: 10px 21px !important;
    }
}
</style>
