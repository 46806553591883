<template>
    <div class="d-flex flex-row justify-between align-items-center">
        <div>
            <h1 class="font-larger">{{ title }}</h1>
        </div>
        <div>
            <menu-dashboard />
        </div>
    </div>
</template>

<script>
import { defineComponent } from "vue";
import MenuDashboard from "@/components/dashboard/menu/MenuDashboard";

export default defineComponent({
    name: "Header",
    props: {
        title: String,
    },
    components: { MenuDashboard },
    setup() {},
});
</script>
