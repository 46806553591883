<template>
    <a href="#menu" @click="drawer = true">
        <span class="material-icons text-secondary">menu</span>
    </a>

    <el-drawer
        v-model="drawer"
        title="I am the title"
        :with-header="false"
        class="w-100"
    >
        <el-radio-group v-model="isCollapse" class="w-100">
            <el-menu
                default-active="2"
                class="el-menu-vertical-demo w-100"
                :collapse="true"
                @open="handleOpen"
                @close="handleClose"
            >
                <router-link to="dashboard">
                    <el-menu-item index="1" class="w-100">
                        <i class="material-icons">home</i>
                        <span class="ml-2">Inicio</span>
                    </el-menu-item>
                </router-link>

                <router-link to="users">
                    <el-menu-item index="1">
                        <i class="material-icons">manage_accounts</i>
                        <span class="ml-2">Gesti&oacute;n de usuarios</span>
                    </el-menu-item>
                </router-link>

                <router-link to="logout">
                    <el-menu-item index="1">
                        <i class="material-icons">logout</i>
                        <span class="ml-2">Cerrar sesi&oacute;n</span>
                    </el-menu-item>
                </router-link>
            </el-menu>
        </el-radio-group>
    </el-drawer>
</template>
<script>
import { defineComponent, ref } from "vue";

export default defineComponent({
    name: "MenuDashboard",
    data: function () {
        return {
            drawer: ref(false),
        };
    },
    setup() {
        const isCollapse = ref(true);
        const handleOpen = (key, keyPath) => {
            console.log(key, keyPath);
        };
        const handleClose = (key, keyPath) => {
            console.log(key, keyPath);
        };
        return {
            isCollapse,
            handleOpen,
            handleClose,
        };
    },
});
</script>
